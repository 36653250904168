import React, { useContext, useEffect } from 'react';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';
import { Grid, Row, Col } from 'react-flexbox-grid';
import BrandCTA from 'components/BrandCta';
import SourceEmitter from 'libs/emitter';
import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';
import ContentBlock from 'components/Content/ContentBlock';
import HeadlineBar from 'components/Content/HeadlineBar';
import ColorBlock from 'components/Content/ColorBlock';

import './program-details.scss';

import whyNDHCPHeroMobile from '../../../assets/images/ND-HCP-whyND-mobile.png';

let Subscription = null;

const programdetailsPage = () => {
  const { appConfigs } = useContext(appContext);

  const indication = appConfigs?.siteOptions?.INDICATIONS?.nutritionHcp;
  // const metaData = appConfigs?.metaData?.nutritionHcp;

  const brandNames = {
    nascobal: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };

  const triggerHashChange = () => {
    const currentHash = window?.location?.hash?.substring(
      1,
      window?.location?.hash?.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  useEffect(() => {
    window.addEventListener('hashchange', triggerHashChange, false);
    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });
    return () => {
      window.removeEventListener('hashchange', triggerHashChange, false);
      Subscription && Subscription.remove();
    };
  }, []);

  const pageTitle = 'NUTRITION DIRECT™ | Why Nutrition Direct™';
  const pageDescription =
    'Nutrition Direct™ turns a complex challenge into an integrated solution';

  const renderProgramDetails = () => {
    return (
      <div>
        {/* <HeadlineBar blue>
          ABOUT{' '}
          <span className="yellow">
            <span
              className="brandname-nutrition txt-uppercase"
              dangerouslySetInnerHTML={{
                __html: brandNames.NUTRITION,
              }}
            />
            ?
          </span>
        </HeadlineBar> */}
        <ContentBlock
          id="about-nutrition-direct-block"
          className="no-padding-top margin-offset"
        >
          <Grid
            fluid
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          >
            <Row>
              <Col xs={12}>
                <div id="hero" className="hide-in-small">
                  <h2>
                    WHY <br className="hide-only-mobile" />
                    <span
                      className="brandname-nutrition txt-uppercase"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.NUTRITION,
                      }}
                    />
                  </h2>
                </div>
                <div id="hero-mobile" className="show-in-small">
                  <img src={whyNDHCPHeroMobile} />
                </div>
              </Col>
            </Row>
            <ColorBlock
              white
              id="colorblock-about-nutrition-direct"
              className="pb-20"
            >
              <Row>
                <Col xs={12}>
                  <h2 className="strong">
                    Designed to help make lifelong nutritional support
                    <sup>1</sup> as simple as possible for your appropriate
                    patients
                  </h2>
                  <p className="strong">
                    Patients receive a monthly delivery with a 30-day supply of
                    nutritional supplements:
                  </p>
                  <ul className="list-guidelines">
                    <li>
                      <span
                        className="brandname-nascobal"
                        dangerouslySetInnerHTML={{
                          __html: brandNames.nascobal,
                        }}
                      />{' '}
                      vitamin B<sub>12</sub> Nasal Spray
                    </li>
                    <li>
                      Oral supplements that meet American Society for Metabolic
                      and Bariatric Surgery (ASMBS) Nutritional Guidelines
                      <sup>2</sup>*
                      <ul className="nested-list">
                        <li>
                          <span
                            className="brandname-bariactiv"
                            dangerouslySetInnerHTML={{
                              __html: brandNames.BARIACTIV,
                            }}
                          />{' '}
                          Calcium + D<sub>3</sub> &amp; Magnesium
                        </li>
                        <li>
                          <span
                            className="brandname-bariactiv"
                            dangerouslySetInnerHTML={{
                              __html: brandNames.BARIACTIV,
                            }}
                          />{' '}
                          Multivitamin, with or without Iron
                        </li>
                        <li className="no-bullet">
                          <span class="blue strong">NOTE:</span> Vitamins are
                          free of milk, egg, fish, shellfish, tree nuts,
                          peanuts, wheat, and soy
                        </li>
                      </ul>
                    </li>
                    <li>
                      <span className="blue strong">
                        Patient savings and support,
                      </span>{' '}
                      including specialty pharmacy services, patient education,
                      and more
                    </li>
                    <li>
                      <span className="blue strong">Home delivery</span> and
                      auto-refills for{' '}
                      <span className="blue strong">as little as $0</span> per
                      month<sup>&dagger;</sup>
                    </li>
                  </ul>
                </Col>
              </Row>
            </ColorBlock>
            <ColorBlock blue className="pb-20">
              <Row>
                <Col xs={12}>
                  <h2 className="strong">
                    Offers an integrated way to deliver information and support
                  </h2>
                  <p className="strong">
                    <span
                      className="brandname-nutrition"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.NUTRITION,
                      }}
                    />{' '}
                    provides resources to help patients stay on track:
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <ul>
                    <li>Dosing instructions</li>
                    <li>Monthly reminders</li>
                    <li>Pharmacy services</li>
                    <li>Product information</li>
                    <li>Dedicated call center</li>
                  </ul>
                </Col>
                <Col xs={12} md={6}>
                  <ul>
                    <li>Recipe cards</li>
                    <li>FAQs</li>
                    <li>Patient education</li>
                    <li>Food diary</li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <p className="strong">
                    Plus, ongoing specialty pharmacy support services for both
                    physicians and patients to help with smooth processing and
                    fulfillment:
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <ul>
                    <li>
                      Calls patients to welcome them and confirm order details
                    </li>
                    <li>
                      Ensures confirmed orders are shipped in 3 to 5 business
                      days
                      <sup className="double-dagger"></sup>
                    </li>
                    <li>
                      Emails confirmation of shipping and tracking information
                    </li>
                    <li>
                      Manages monthly refills of nutritional supplements and
                      educational materials
                    </li>
                  </ul>
                </Col>
                <Col xs={12} md={6}>
                  <ul>
                    <li>
                      Facilitates switch from chewable to tablet/capsule
                      formulations
                    </li>
                    <li>
                      Addresses and processes insurance claims for your patients
                    </li>
                  </ul>
                </Col>
              </Row>
            </ColorBlock>
            <ColorBlock white className="pb-20">
              <Row>
                <Col xs={12}>
                  <h2 className="strong mb-0">Contact ProCare PharmacyCare:</h2>
                  <p>
                    Tel: 855-828-1488
                    <br />
                    Fax: 855-828-1492
                    <br />
                    Monday through Friday, 8 <sub className="time">AM</sub> to 8{' '}
                    <sub className="time">PM</sub> ET
                  </p>
                  <p className="footnote asterik">
                    ASMBS Integrated Health Nutritional Guidelines for the
                    Surgical Weight Loss Patient &mdash; 2016 Update:
                    Micronutrients.
                  </p>
                  <p className="footnote dagger">
                    Patients may redeem this offer ONLY when accompanied by a
                    valid prescription. Offer is valid up to a maximum benefit
                    of $150. Offer is not valid for patients whose prescriptions
                    are reimbursed in whole or in part under Medicaid, Medicare,
                    Medigap, VA, DoD, TRICARE, or any other federal or state
                    programs (such as medical assistance programs) or where
                    otherwise prohibited by law. Offer is not valid in VT or
                    where prohibited in whole or in part. This offer may be
                    amended or ended at any time without notice.
                  </p>
                  <p className="footnote double-dagger">
                    Note: Incomplete prescription fax forms may cause delivery
                    delays.
                  </p>
                </Col>
              </Row>
            </ColorBlock>
          </Grid>
        </ContentBlock>
        <Row>
          <Col xs={12} md={6}>
            <BrandCTA
              href="/nutritiondirect/hcp/nasal-spray"
              LinkCaption="LEARN MORE ABOUT NASCOBAL®"
            >
              LEARN MORE ABOUT NASCOBAL
              <sup className="brand-reg-mark">&reg;</sup>
            </BrandCTA>
          </Col>
          <Col xs={12} md={6}>
            <BrandCTA
              href="/nutritiondirect/hcp/bariactiv"
              LinkCaption="ABOUT BARIACTIV® SUPPLEMENTS"
            >
              ABOUT BARIACTIV<sup className="brand-reg-mark">&reg;</sup>{' '}
              SUPPLEMENTS
            </BrandCTA>
          </Col>
        </Row>
      </div>
    );
  };

  const renderPage = () => {
    return (
      <div id="nutrition-hcp-program-details">
        {renderProgramDetails()}
        <ScrollableAnchor id="isi_anchor">
          <div />
        </ScrollableAnchor>
      </div>
    );
  };

  return (
    <Layout
      indication={indication}
      className="nutrition-hcp-program-details-page"
    >
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default programdetailsPage;
